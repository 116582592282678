import React, { useEffect, useState } from "react";
import styled from 'styled-components';
import {
    SimpleTable,
    SimpleTableBody,
    SimpleTableContainer,
    SimpleTableHead,
    SimpleTableHeader,
    SimpleTableRow,
    SimpleTableData,
    SimpleTableWrapper
} from "cdk-radial";
import "./style.css";
import { API_TYPE } from "./certificationConstants";
import moment from "moment";
import { API_TYPES } from "../Utils/constants";

const columns = [
    {
        field: 'id',
        headerName: 'API Id',
        minWidth: 50,
        width: 300
    },
    {
        field: 'name',
        headerName: 'API Name',
        minWidth: 50,
        width: 230
    },
    {
        field: 'freeTrialOverrideEndDate',
        headerName: 'Free Trial End Date',
        minWidth: 80,
        width: 150
    },
    {
        field: 'type',
        headerName: 'API Type',
        minWidth: 50,
        width: 100
    },
    {
        field: 'description',
        headerName: 'API Description',
        minWidth: 50,
        width: 400
    },
    {
        field: 'providerId',
        headerName: 'Org Id',
        minWidth: 50,
        width: 250
    }, {
        field: 'providerName',
        headerName: 'Org Name',
        minWidth: 50,
        width: 250
    },
    {
        field: 'pricingPlanType',
        headerName: 'Pricing Plan Type',
        minWidth: 50,
        width: 150
    },
    {
        field: 'pricingPlanName',
        headerName: 'Pricing Plan Name',
        minWidth: 50,
        width: 150
    }
]

 //get plan type
 const getPlanType = (planData = {}) => {
    if (planData.type && planData.type.toLowerCase) {
      switch (planData.type.toLowerCase()) {
        case "advanced":
          return "Subscription w/ Overage";
        case "tiered":
          return "Transactional";
        case "subscription":
          return "Subscription";
        case "free":
          return "Free";
        default:
          return "Unknown";
      }
    } else {
      return "Unknown";
    }
  };

const ZebraSimpleTableRow = styled(SimpleTableRow)`
  :nth-child(even) {
    background-color: ${({ theme }) => theme.color.zebraStripe.background.value};
    border-top: 1px solid ${({ theme }) => theme.color.gray[200].value};
    :hover {
      background-color: ${({ theme }) => theme.color.zebraStripe.hover.value};
    }
  }
  &:last-child {
    border-bottom: none;
    height: 64px;
  }
`;

const StickySimpleTableHeader = styled(SimpleTableHeader)`
  position: sticky;
  top: 0;
`;

const StyledSimpleTableWrapper = styled(SimpleTableWrapper)`
  overflow: auto;
  border: 0;
  width: 100%
`;
const StyledSimpleTable = styled(SimpleTable)`
  position: relative;
  overflow: auto;
`;

const StyledSimpleTableContainer = styled(SimpleTableContainer)`
  max-height: 515px;
  width: auto;
  overflow: auto;
`;

export const APIDetailsTable = ({ apiDetails,activeAsyncApiIntegrations }) => {
    const [columnData, setColumnData] = useState([]);

    useEffect(() => {
        if (activeAsyncApiIntegrations && activeAsyncApiIntegrations.length > 0) {
            const asyncIntegrationMap = new Map(
              activeAsyncApiIntegrations.map((item) => [
                item.asyncApi.id,
                item.freeTrialOverrideEndDate,
              ])
            );

            const asyncIntegrations = activeAsyncApiIntegrations.map(
              (integration) => {
                const { asyncApi } = integration;
                let displayName;
                let apiProviderName;
                apiDetails.find((int) => {
                  if (int.id === asyncApi.id) {
                    displayName = int.displayName;
                    apiProviderName = int.providers[0].apiProviderName;
                  }
                });
                return {
                  id: asyncApi.id,
                  name: asyncApi.name,
                  displayName,
                  description: asyncApi.description,
                  apiType: API_TYPES.ASYNC,
                  apiFields: integration.apiFields,
                  providers: [
                    {
                      apiProviderName,
                      entityId: asyncApi.orgId,
                      pricingPlan: asyncApi.apiPricingPlan,
                      id: asyncApi.id,
                    },
                  ],
                };
              }
            );

            const restIntegrations = apiDetails.filter(
              (integration) => integration.apiType === API_TYPES.REST
            );
            apiDetails = [...asyncIntegrations, ...restIntegrations].map(item => {
                const freeTrialOverrideEndDate = asyncIntegrationMap.get(item.id);
                if (freeTrialOverrideEndDate !== undefined) {
                    return { ...item, freeTrialOverrideEndDate };
                }
                return item;
            });
        }
        let colData = [];
        if (apiDetails && apiDetails.length) {
            let row = {};
            apiDetails.forEach(item => {
                let formattedDate = Date.parse(item.freeTrialOverrideEndDate) && new Date(item.freeTrialOverrideEndDate).toISOString().slice(0, 10);
                row = {
                    id: item.id,
                    name: item.displayName,
                    description: item.description,
                    freeTrialOverrideEndDate:item.freeTrialOverrideEndDate ?moment(formattedDate).format("DD MMM YYYY"):'N/A',
                    type: API_TYPE[item.apiType],
                    providerId: item.providers[0].entityId,
                    providerName: item.providers[0].apiProviderName,
                    pricingPlanType: getPlanType(item.providers[0].pricingPlan),
                    pricingPlanName: item.providers[0].pricingPlan.name,

                };
                colData.push(row);
            });
            setColumnData(colData);
        }
    }, [apiDetails]);

    return (
        <StyledSimpleTableWrapper>
            <StyledSimpleTableContainer>
                <StyledSimpleTable>
                    <SimpleTableHead>
                        <SimpleTableRow>{
                            columns.map((column) => {
                                return <StickySimpleTableHeader colSpan="1">{column.headerName}</StickySimpleTableHeader>
                            })}

                        </SimpleTableRow>
                    </SimpleTableHead>
                    <SimpleTableBody>
                        {
                            columnData.map((item) => {
                                return (
                                    <ZebraSimpleTableRow className="">
                                        {
                                            columns.map((col) => {
                                                return (
                                                    <SimpleTableData className="table-row" style={{ minWidth: col.width }}>
                                                        {col.field !== 'description' ? item[col.field] || 'N/A' : (<ReadMore description={item[col.field]} />)}
                                                    </SimpleTableData>)
                                            })
                                        }
                                    </ZebraSimpleTableRow>
                                )
                            })
                        }
                    </SimpleTableBody>
                </StyledSimpleTable>
            </StyledSimpleTableContainer>
        </StyledSimpleTableWrapper>)
}

const ReadMore = ({ description = "N/A" }) => {
    const DESC_LIMIT = 100;
    const [showMore, setShowMore] = useState(false);
    const desc = description.replace(/<[^>]*>|#/g, '');

    return (
        <div className="desc-text-wrap">
            <div>{
                showMore ?
                    desc :
                    desc.slice(0, DESC_LIMIT)
            }{
                    !showMore && desc.length > DESC_LIMIT && '...'
                }
            </div>
            {desc.length > DESC_LIMIT &&
                <div className="show-more-link" dataTestId="show-more-link" onClick={() => { setShowMore(!showMore) }}>
                    {showMore ? 'Show Less' : 'Show More'}
                </div>
            }
        </div>
    );
}
