import { useEffect, useRef, useState } from 'react';

import { AgGridReact } from 'ag-grid-react';
import {
    CELL_CLASSES,
    TableContainer,
    TableContent,
    TableHeader,
    TABLE_LAYOUT_OPTIONS,
    CELL_RENDERERS,
    usePreventTableScrollingRef,
    Dialog
} from 'cdk-radial';
import classNames from 'classnames';


export default function ApisListTable({ apis, setSelectedApis, isViewOnly, setIsVisibilityDisabled, setIsPrivate }) {
    const [data, setData] = useState([]);
    const [gridApi, setGridApi] = useState(null);
    const [searchInputValue, setSearchInputValue] = useState('');
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [selectedApi, setSelectedApi] = useState(null);
    const [isRemoveError, setIsRemoveError] = useState(false)

    
    const keysToCheck = [
        'Contact_Details',
        'API_Terms',
        'External_Doc',
        'Public_Pricing',
         'API_Summary',
         'Show_In_Directory'
    ];
    const gridRef = useRef();
    const columns = [{
        cellClass: CELL_CLASSES.CHECKBOX,
        checkboxSelection: true,
        field: 'checkbox',
        headerClass: [CELL_CLASSES.CHECKBOX, CELL_CLASSES.SELECT_ALL],
        headerName: '',
        maxWidth: 55,
        minWidth: 55,
        resizable: false,
        hide: isViewOnly,
    }, {
        cellRenderer: CELL_RENDERERS.TEXT,
        field: 'api_name',
        headerName: 'API Name',
        minWidth: 150,
        width: 250,
        sortable: true,
        cellStyle: { overflow: 'auto' }
    }, {
        cellRenderer: CELL_RENDERERS.TEXT,
        field: 'api_id',
        headerName: 'API ID',
        width: 220
    }, {
        cellRenderer: CELL_RENDERERS.TEXT,
        field: 'api_type',
        headerName: 'API Type',
        width: 100,
    },
    {
        cellRenderer: CELL_RENDERERS.TEXT,
        field: 'visibility',
        headerName: 'Visibility',
        width: 100,
    },
    {
        cellRenderer: CELL_RENDERERS.TEXT,
        field: 'org_name',
        headerName: 'Publisher',
        minWidth: 100,
        cellStyle: { overflow: 'auto' }
    }];
    const tableContentRef = usePreventTableScrollingRef();

    useEffect(() => {
        const tableData = [];
        apis.forEach((api) => {
            tableData.push({
                api_name: api?.api_name,
                api_id: api?.api_id,
                api_type: api?.api_type === 'api' ? 'Rest' : (api?.api_type === 'async-api' ? 'Async' : api?.api_type),
                visibility: api?.visibility?.toLowerCase() === 'private' ? 'Private' : 'Public',
                org_name: api?.org_name,
                Contact_Details: api?.has_contact,
                API_Terms: api?.has_terms,
                External_Doc: api?.has_external_doc,
                Public_Pricing: api?.has_price,
                isExistingApi: api?.isExistingApi,
                API_Summary: api?.has_api_summary,
                Show_In_Directory: api?.show_in_directory
            });
        });
        setData(tableData);
    }, [apis]);

    const handleSearchInputChange = searchInput => {
        setSearchInputValue(searchInput);
        if (gridApi) {
            gridApi.setQuickFilter(searchInput);
            gridApi.refreshCells({
                force: true,
            });
            const displayedRowsCount = gridApi.getDisplayedRowCount();

            if (searchInput && displayedRowsCount === 0) {
                gridApi.showNoRowsOverlay();
            } else {
                gridApi.hideOverlay();
            }
        }
    };
    const headerProps = {
        className: '',
        dataTestId: `table-header`,
        headerTitle: isViewOnly ? 'APIs Selected for Bundling' : 'Select APIs',
        hideBorder: false,
        hideTableHeader: false,
        hideTableTitle: false,
        id: `table-header`,
        isAlwaysExpanded: false,
        isSearchable: isViewOnly ? false : true,
        isPrintable: false,
        isDownloadable: false,
        onSearchInputChange: handleSearchInputChange,
        overflowButtonProps: undefined,
        searchInputName: 'table-search-input-name',
        searchInputValue: searchInputValue,
        searchPlaceholder: 'Search all columns'
    };

    const handleGridReady = (gridParams) => {
        setGridApi(gridParams?.api);
        if (gridParams?.api) {
            gridParams?.api?.sizeColumnsToFit();
            gridParams?.api?.refreshCells({
                force: true,
            });
            if (!isViewOnly) {
                gridParams?.api?.forEachNode(node => {
                    if (node?.data?.isExistingApi)
                        node.setSelected(true)
                })
            }
        }
    };

    const handleApiSelection = (params) => {
        if (!isViewOnly) {
            let selectedApis = params?.api?.getSelectedNodes().map(row => row?.data)
            setSelectedApis(selectedApis)
            if (selectedApis?.some(api => api.visibility === 'Private')) {
                setIsPrivate(true)
                setIsVisibilityDisabled(true)
            }
            else {
                setIsVisibilityDisabled(false)
            }
        }
    }

    const onRowSelected = (event) => {
        if (event.node.selected &&
            !(
                event.data.Public_Pricing && 
                event.data.API_Terms && 
                event.data.Contact_Details && 
                event.data.External_Doc && 
                event.data.API_Summary && 
                event.data.Show_In_Directory
            )) {
            setIsDialogOpen(true);
            
            if (event?.data?.isExistingApi) {
                setIsRemoveError(true);
            } else {
                event.node.setSelected(false);
                setIsRemoveError(false);
            }
            setSelectedApi(event?.data);
            
        } else if (!event?.node?.selected && event?.data?.isExistingApi) {
            setIsRemoveError(true);
            event.node.setSelected(true);

        }
    };

    return <><TableContainer
        id="ag-grid-container"
        className={classNames('select-apis-table', 'ag-theme-balham')}
    >
        <TableHeader {...headerProps} />
        <TableContent
            data-testid="table-content"
            className="select-apis-table-content"
            ref={tableContentRef}
        >
            <AgGridReact
                columnDefs={columns}
                domLayout={TABLE_LAYOUT_OPTIONS.AUTO_HEIGHT}
                rowData={data}
                rowHeight={50}
                style={{ width: '100%' }}
                ref={gridRef}
                onGridReady={handleGridReady}
                rowSelection='multiple'
                onSelectionChanged={handleApiSelection}
                onRowSelected={onRowSelected}
                pagination={true}
                paginationPageSize={10}
                paginationPageSizeSelector={[10, 20, 50, 100]}
                suppressRowClickSelection={true}
            />
        </TableContent>
    </TableContainer>

        {!!isDialogOpen && <Dialog
            buttonsProps={[
                {
                    id: 'dialog-action-1',
                    onClick: () => setIsDialogOpen(false),
                    text: 'Close',
                    variant: 'text'
                },
            ]}
            isOpen={isDialogOpen}
            dataTestId="bundle-warning-dialog"
            id="bundle-warning-dialog"
            onClose={() => setIsDialogOpen(false)}
            hideHeader={true}
        >
            {!!isRemoveError ?
                <>You cannot remove an API once the bundle creation is complete. </> :
                <>
                    This API cannot be added to the bundle as it is missing the following required information:
                    <ul>
                        {
                            selectedApi && Object.keys(selectedApi).filter(key=> keysToCheck.includes(key) && !selectedApi[key]).map(key => {
                                return <li>
                                    {key.replace(/_/g, ' ')}
                                </li>
                            })
                        }
                    </ul>
                </>}
        </Dialog>}
    </>;

}